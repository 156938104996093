<!-- @format -->
<script lang="ts">
  import SocialMediaWrapper from "./SocialMediaWrapper.svelte";

  export let title: string;
  export let instagramFeeds: Instagram[];
  export let sc_name: string;

  let innerWidth: number = 0;
</script>

<svelte:window bind:innerWidth />

{#if instagramFeeds.length}
  <SocialMediaWrapper>
    <svelte:fragment slot="lift-title">
      {title}
    </svelte:fragment>
    <svelte:fragment slot="content">
      {#each instagramFeeds.slice(0, innerWidth > 768 ? 8 : 3) as ig}
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="instagram-tool-box">
            <img
              src="/theme/eften2025/images/Instagram-black.png"
              class="mr-1"
              width="30"
              alt="Instagram-icon" />
            <span class="sc-name">{sc_name}</span>
          </div>
          <div class="w-100 mb-3 border" style="position: relative;">
            <a href={ig.permalink} target="_blank" rel="noreferrer">
              <img
                class="w-100 image"
                src={ig.thumbnail_url || ig.media_url}
                alt={ig.caption} loading="lazy" 
              />
              {#if ig.media_type && ig.media_type.toLowerCase() == 'video'}
                <img
                  src="/theme/eften2025/images/play-icon.png"
                  alt="play-button"
                  class="play-button"
                />
              {/if}
            </a>
          </div>
          <br />
        </div>
      {/each}
    </svelte:fragment>
  </SocialMediaWrapper>
{/if}

<style lang="scss">
  .sc-name {
    font-size: 18px;
  }
  .instagram-tool-box {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: var(--brand-color-3);
  }
  .image {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .play-button {
    position: absolute;
    width: 20%;
    top: 40%;
    left: 40%;
    filter: invert(100%);
    opacity: 0.85;
  }

  div.border {
    overflow: hidden;
  }
  a {
    img {
      -webkit-transition: 0.5s all ease-in-out;
      transition: 0.5s all ease-in-out;
    }
  }
  a:hover {
    img {
      transform: scale(1.13);
    }
  }
</style>
